import img1 from "../img/partner/1.svg";
import img2 from "../img/partner/2.svg";
import img3 from "../img/partner/3.svg";
import img4 from "../img/partner/4.svg";
import img5 from "../img/partner/5.svg";
export const partners = [
	{
		img: img1,
	},
	{
		img: img2,
	},
	{
		img: img3,
	},
	{
		img: img4,
	},
	{
		img: img5,
	},
	{
		img: img1,
	},
	{
		img: img2,
	},
	{
		img: img3,
	},
	{
		img: img4,
	},
	{
		img: img5,
	},
	{
		img: img1,
	},
	{
		img: img2,
	},
	{
		img: img3,
	},
	{
		img: img4,
	},
	{
		img: img5,
	},
	{
		img: img1,
	},
	{
		img: img2,
	},
	{
		img: img3,
	},
	{
		img: img4,
	},
	{
		img: img5,
	},
];
