import React, { useRef } from "react";
import { Link , useParams} from "react-router-dom";
import Slider from "react-slick";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import img from "../../assets/img/creator1.png";
import { Clock, ETHIcon, Heart } from "../../icon/IconTheme";
import CountdownItem from "../CountdownItem";
import { NextSlide, PrevSlide } from "./components/SliderNav";
import { recommendedData } from "../../data/recommended.js";
import { useEffect } from "react";
import { useAccount } from "wagmi";


console.log(recommendedData)
const Creators = ({ title, className, btnTxt}) => {
	let {refLink} = useParams();
	let stringw = "1900"
	console.log(Number(stringw));

	const {property} = useParams();
	const sliderRef = useRef();
	const nextSlide = () => {
		sliderRef.current.slickNext();
	};
	const prevSlide = () => {
		sliderRef.current.slickPrev();
	};
	const settings = {
		dots: false,
		infinite: true,
		speed: 300,
		slidesToShow: 3,
		slidesToScroll: 1,
		arrows: false,
		margin: 30,
		responsive: [
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
				},
			},
		],
	};
	return (
		<>
			<section className={`creators-section ${className ? className : ""}`}>
				<div className="container">
					<div className="section-title">
						{title && <h4 className="title">{title}</h4>}

						<div className="nav-btns">
							<div onClick={prevSlide}>
								<PrevSlide />
							</div>
							<div onClick={nextSlide}>
								<NextSlide />
							</div>
						</div>
					</div>
					{/* Creators Slider */}
					<div className="creator-slider">
						<Slider {...settings} ref={sliderRef}>
							{recommendedData.map((item, i) => (
								<CreatorCard data={item}key={i} />
							))}
						</Slider>
					</div>
					{/* Creators Slider */}
					<div className="mt-4 pt-1 text-center">
						<Link to="#" className="cmn-btn secondary-btn px-30">
							{btnTxt ? btnTxt : "Load More"}
						</Link>
					</div>
				</div>
			</section>
		</>
	);
};

export const CreatorCard = (props) => {
	const {address} = useAccount();
	let {refLink} = useParams();
	const [ref, setRef] = React.useState([]);
	useEffect(() => {
		setRef(refLink);
		if (!refLink){
			setRef("0xA262428DF7F2bC23ACE826305cb4beDab22957e6")
		}
		console.log(refLink);
	}, [refLink]);
	
	let	data = props.data;


	let viewpropertyhandler = () => {
		if (!address) {
			alert("Please sign in to view property");
		} else {
			window.location.href = `https://xendcapital.com/${ref}/${data.ca}`;
		}
	}
	return (
		<div className="creator-card">
			<div className="creator-img">
				<Link to="#">
					<img width="500" height="250"src={data.img} alt="" />
				</Link>
				<div >
					{/* <Clock /> */}
					{/* <CountdownItem targetDate={`December 31, 2022 00:00:00`} /> */}
				</div>
				{/* <div className="right-badge chip">
					<Heart /> <span>12</span>
				</div> */}
			</div>
			<div className="creator-cont">
				<a onClick={viewpropertyhandler}>
					<h5 className="name">View Property</h5>
				</a>
				<ul className="info">
					<li>
						<span>Type</span>
						<h6>{data.type}</h6>
					</li>
					<li>
						<span>Pieces</span>
						<h6>{data.pieces}</h6>
					</li>
					<li>
						<span>Price Per Piece </span>
						<h6>{data.pricePerPiece}</h6>
					</li>
					<li>
						<span>Expected Yield</span>
						<h6>
							<small>APR per Piece</small> ~ {data.APR}%
						</h6>
					</li>
					<li>
						<span>Address</span>
						<h6>{data.address}</h6>
					</li>
					<li>
						<span>Price</span>
						<h6>
							$  {"      "}{data.ListingPrice}
						</h6>
					</li>
				</ul>
			</div>
		</div>
	);
};

export const PieceCard = (props) => {
	
	let {property} = useParams();
	let	data = props.data;
	return (
		<div className="creator-card">
			<div className="creator-img">
				<Link to="#">
					<img width="500" height="250"src={data.img} alt="" />
				</Link>
				<div className="left-badge chip">
					<Clock />
					<CountdownItem targetDate={`December 31, 2022 00:00:00`} />
				</div>
				<div className="right-badge chip">
					<Heart /> <span>12</span>
				</div>
			</div>
			<div className="creator-cont">
				<a href={`${property}/${data.type}`}>
					<h5 className="name">View Piece</h5>
				</a>
				<ul className="info">
					<li>
						<span>Type</span>
						<h6>{data.type}</h6>
					</li>
					<li>
						<span>Price Per Piece </span>
						<h6>{data.pricePerPiece}</h6>
					</li>
					<li>
						<span>Expected Yield</span>
						<h6>
							<small>APR per Piece</small> ~ {data.APR}%
						</h6>
					</li>
					<li>
						<span>Price</span>
						<h6>
							<ETHIcon />  {"      "}{data.ListingPrice}
						</h6>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default Creators;
