import React from "react";
import { useParams } from "react-router-dom";
import { property } from "../../../assets/data/property_details";
import ActivityCard from "../../../components/activity-card/ActivityCard";

const filterdata = ["Listings"];
const StoreProperty = () => {
	const {property} = useParams()
	const [data, setData] = React.useState([]);
	const query = async () => {
		let url = `https://real-estate-f83b0-default-rtdb.firebaseio.com/0x81636cb63146A162253Bdd9A87121813469ec163.json`;
		const query = await fetch(url);
		const data = await query.json();
		let arr = [];
		for (const key in data) {
			arr.push({
				APR : data[key].APR,
				Baths : data[key].Baths,
				Days_Listed : data[key]["Days Listed"],
				Piece_Price : data[key]["Piece Price"],
				Price : data[key].Price,
				Price_per_square : data[key]["Price per square"],
				garage : data[key].garage,
			})
			setData(arr[0]);
	}}
	React.useEffect(
		() => {
			query();
		}, []
	)
	//
	return (
		<>
			<section>
				<div className="container">
					<div className="row g-4">
						<div className="col-lg-6">
							<div className="card __card">
								<div className="card-header">
									<h5 className="card-title">Property</h5>
								</div>
								<div className="card-body">
									<div className="__property-wrapper">
										{property &&
											["", "", "","","","",""].map(({ subtext, value }, i) => (
												<div className="__property-card" key={i}>
													<div className="subtext">{Object.keys(data)[i]}</div>
													<h5 className="value">{Object.values(data)[i]}</h5>
												</div>
											))}
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<ActivityCard data={["", "", ""]} filter={filterdata} />
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default StoreProperty;
