import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import user_img from "../../assets/img/client1.png";
import logo from "../../assets/img/logo.png";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount } from 'wagmi'
import { Web3Button } from "@web3modal/react";






import { Bar, Close, SearchIcon, Wallet } from "../../icon/IconTheme";
const Header = () => {
	const { address, isConnected, isDisconnected } = useAccount()
	const { isOpen, open, close } = useWeb3Modal();
	const [menuOpen, setMenuOpen] = useState();
	return (
		<header>
			<div className="container">
				<div className="header-wrapper">
					<Link to="/" className="logo">
						<img  src={logo} alt="" />
					</Link>
					<div
						className="bar ms-auto"
						onClick={() => setMenuOpen(!menuOpen)}
					>
						<Bar />
					</div>
					<div className={`menu-area  ${menuOpen ? "active" : ""}`}>
						<div className="close" onClick={() => setMenuOpen(!menuOpen)}>
							<Close />
						</div>
						{/* <SearchForm /> */}
						<ul className="menu" onClick={() => setMenuOpen(!menuOpen)}>
							<li>
								<NavLink to="/">Home</NavLink>
							</li>
							<li>
								<a href="https://find-and-update.company-information.service.gov.uk/company/14567175">Registration</a>
							</li>
							{/* <li>
								<NavLink to="/nfts">Drops</NavLink>
							</li>
							<li>
								<NavLink to="/dashboard">Activity</NavLink>
							</li> */}
						</ul>
						<div className="header-btns d-flex flex-wrap">
							{!isConnected && (
								<Link
									to="#"
									className="cmn-btn btn-outline"
									onClick={() => {;
									open()}}
								>
									Sign In
								</Link>
							)}
							{isConnected && <Web3Button />}
						</div>
					</div>
					{isConnected && (
						<>
							<div className="d-flex header-btns">
								<a onClick={open} className="rounded-btn">
									<Wallet />
								</a>
								<Link to="/dashboard" className="author-img">
									<img src={user_img} alt="" />
								</Link>
							</div>
							{/* {navigate("/dashboard")} */}
						</>
					)}
				</div>
			</div>
		</header>
	);
};
export const SearchForm = () => {
	return (
		<form className="search-form">
			<div className="__search-form">
				<input
					type="text"
					className="form-control"
					placeholder="Search items, collections, and accounts"
				/>
				<div className="left-icon">
					<SearchIcon />
				</div>
			</div>
		</form>
	);
};

export default Header;
