import React from "react";
import Chart from "react-apexcharts";
const RadialChart = () => {
	const series = [44, 55, 67];
	const options = {
		chart: {
			type: "radialBar",
		},
		colors: ["#FFBD00", "#F20089", "#19995E"],
		plotOptions: {
			radialBar: {
				dataLabels: {
					value: {
						fontSize: "12px",
					},
					name: {
						fontSize: "24px",
					},
					total: {
						show: true,
						label: "$96.4k",
						formatter: function (w) {
							return "Total";
						},
					},
				},
			},
		},
		labels: ["Total Profit", "Total Investment", "Properties"],
	};
	return (
		<div className="card __card">
			<div className="card-header">
				<h6 className="card-title">Sales breakdown</h6>
			</div>
			<div className="card-body">
				<div className="radial-chart">
					<Chart
						options={options}
						series={series}
						type="radialBar"
						height={300}
					/>
					<ul className="chart-info">
						<li>
							<span className="indicator"></span>
							<span>Properties </span>
							<span>$48.3k</span>
						</li>
						<li>
							<span
								className="indicator"
								style={{ background: "#FFBD00" }}
							></span>
							<span>Total Profit </span>
							<span>$26.5k</span>
						</li>
						<li>
							<span
								className="indicator"
								style={{ background: "#F20089" }}
							></span>
							<span>Total Investment </span>
							<span>$17.6k</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default RadialChart;
