import {
	Facebook,
	LinkedinIn,
	Pinterest,
	Slack,
	Twitter,
} from "../../icon/IconTheme";

export const social_icons = [
	{
		icon: <Facebook />,
		link: "#",
	},
	{
		icon: <Slack />,
		link: "#",
	},
	{
		icon: <Twitter />,
		link: "#",
	},
	{
		icon: <Pinterest />,
		link: "#",
	},
	{
		icon: <LinkedinIn />,
		link: "#",
	},
];
