import React, { useEffect } from "react";
import About from "../../components/about/About";
import Banner from "../../components/banner/Banner";
import ChooseUs from "../../components/choose/ChooseUs";
import CreateNft from "../../components/create-nft/CreateNft";
import Creators from "../../components/creators/Creators";
import Customers from "../../components/customers/Customers";
import Partner from "../../components/partner/Partner";
import Video from "../../components/video/Video";
import { useParams } from "react-router-dom";

const Home = () => {
	let {refLink} = useParams();
	

	let messageBody = {
		referrer : refLink
	}

	useEffect(() => {
		if (refLink == undefined) {
			refLink = '0xA262428DF7F2bC23ACE826305cb4beDab22957e6';
		}

		console.log(refLink);
	}, [refLink]);
	return (
		<>
			<Banner />
			<Partner />
			<About />
			<Creators title="Recommended Properties" />
			{/* <Creators
				title="Recommended Properties"
				btnTxt="Explore Properties"
				className="bg-section"
			/> */}
			<Customers />
			{/* <Video /> */}
			<ChooseUs />
			<CreateNft />
		</>
	);
};

export default Home;
