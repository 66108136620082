import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Dashboard from "./pages/dashboard/Dashboard";
import Home from "./pages/home/Home";
import ListedProperties from "./pages/listed-properties/ListedProperties";
import Stats from "./pages/stats/Stats";
import Store from "./pages/store/Store";
import {
	EthereumClient,
	modalConnectors,
	walletConnectProvider,
  } from "@web3modal/ethereum";

import { Web3Modal } from "@web3modal/react";

import { configureChains, createClient, WagmiConfig } from "wagmi";

import { bsc } from "wagmi/chains";



const customChain = {
	id: 404,
	name: 'tHepton',
	network: 'tHepton',
	nativeCurrency: {
	  decimals: 18,
	  name: 'Hepton',
	  symbol: 'HTE',
	},
	rpcUrls: {
	  default: { http: ['https://testnet.hepton.io'] },
	},
	blockExplorers: {
	  etherscan: { name: 'HeptonScan', url: 'https://testnet.heptonscan.com' },
	  default: { name: 'HeptonScan', url: 'https://testnet.heptonscan.com' },
	},
	contracts: {
	  multicall3: {
		address: '0xca11bde05977b3631167028862be2a173976ca11',
		blockCreated: 11907934,
	  },
	},
  }
  const chains = [bsc];

// Wagmi client
const { provider } = configureChains(chains, [
  walletConnectProvider({ projectId: "d3873ed18b4ac26cf6f5dffdb2fe9cbd" }),
]);
const wagmiClient = createClient({
  autoConnect: true,
  connectors: modalConnectors({ appName: "web3Modal", chains }),
  provider,
});




//
// Web3Modal Ethereum Client
const ethereumClient = new EthereumClient(wagmiClient, chains);

function App() {
	return (
		<>
		 <WagmiConfig client={wagmiClient}>
			<BrowserRouter>
				<Header />
				<Routes>
					<Route path="/:refLink" element={<Home />} />
					<Route path="/" element={<Home />} />
					<Route path=":refLink/dashboard" element={<Dashboard />} />
					<Route path="/:refLink/:property/" element={<Store />} />
					<Route
						path=":refLink/listed-properties"
						element={<ListedProperties />}
					/>
					
				</Routes>
				<Footer />
			</BrowserRouter>
			<Web3Modal
        projectId="d3873ed18b4ac26cf6f5dffdb2fe9cbd"
		themeColor = "green"
        ethereumClient={ethereumClient}
      />
			</WagmiConfig>
		</>
	);
}

export default App;
