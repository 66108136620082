import React from "react";
import { Link } from "react-router-dom";
import img1 from "../../assets/img/choose/1.png";
import img2 from "../../assets/img/choose/2.png";
import img3 from "../../assets/img/choose/3.png";
import { useWeb3Modal } from "@web3modal/react";
const ChooseUs = () => {
	const {open} = useWeb3Modal;
	return (
		<section className="choose-section">
			<div className="choose-shape"></div>
			<div className="choose-shape2"></div>
			<div className="container">
				<div className="row gy-5 flex-wrap-reverse align-items-center justify-content-between">
					<div className="col-lg-6">
						<div className="choose-content">
							<h2 className="title">How is works?</h2>
							<p>
							Xend buys/Builds a rental property
Rents it out to tenants,but instead of letting the capital tired down in a single property
							</p>
							<p>
							Xend divides the property into pieces and each piece is represented as NFTs.Now these piece would be retailed to investors across the world while xend manages the property and pays monthly/annual percentage interest to the to you, the owner.
							</p>
							<a onClick={open} className="cmn-btn">
								Connect Wallet
							</a>
						</div>
					</div>
					<div className="col-lg-6 col-xl-5">
						<div className="choose-images mx-auto me-lg-0">
							<div className="row g-2 g-sm-4 g-lg-2 g-xl-4">
								<div className="col-12">
									<img src={img1} className="w-100 img-1" alt="" />
								</div>
								<div className="col-6">
									<img src={img2} className="w-100 img-2" alt="" />
								</div>
								<div className="col-6">
									<img src={img3} className="w-100 img-2" alt="" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ChooseUs;
