import React from "react";
import { Link } from "react-router-dom";
import banner_img from "../../assets/img/bg.png";
import img from "../../assets/img/creator1.png";

import {
	BannerArrowIcon,
	Clock,
	Ellipsis,
	ETHIcon,
	Heart,
} from "../../icon/IconTheme";
import { useAccount } from 'wagmi'
import { useWeb3Modal } from "@web3modal/react";



import CountdownItem from "../CountdownItem";
const Banner = () => {
	const { address, isConnected, isDisconnected } = useAccount()
	const { isOpen, open, close } = useWeb3Modal();


	return (
		<section
			className="banner-section"
			style={{
				background: `url(${banner_img}) no-repeat center -200px / cover `,
			}}
		>
			<div className="container">
				<div className="banner-wrapper">
					<div className="banner-content">
						<h1 className="title">
						 
							Invest in <strong>Real Estate</strong>  {" "}
							<strong>Using</strong> NFTs as Proof of{" "}
							<strong>Authenticity.</strong> <BannerArrowIcon />
						</h1>
						<p className="txt">
							We are a decentralized real estate marketplace that allows you to invest in real estate from anywhere in the world using blockchain technology.
						</p>
						<div className="btn-grp">
							{!isConnected && <a onClick={open} className="cmn-btn">
								 Sign In
							</a>
							}
							{isConnected && <Link to="/dashboard" className="cmn-btn">
								Dashboard 
							</Link>
							}
							<Link
								to="/listed-properties"
								className="cmn-btn secondary-btn"
							>
								Explore Properties 
							</Link>
						</div>
					</div>
					<div className="creator-card">
						<div className="creator-img">
							<Link to="#">
								<img src={"https://ap.rdcpix.com/322aa7af6e6a15fda91148c6a340b6b1l-m2329288323od-w480_h360_x2.webp"} alt="" />
							</Link>
							{/* <div className="left-badge chip">
								<Clock />
								<CountdownItem
									targetDate={`January 31, 2023 00:00:00`}
								/>
							</div> */}
							
						</div>
						<div className="creator-cont">
							<Link to="#">
								<h5 className="name">Ont, CA 02</h5>
							</Link>
							<ul className="info">
								<li>
									<span>Address</span>
									<h6>Toronto, Ca</h6>
								</li>
								<li>
									<span>Starting from</span>
									<h6>
										 $900 / Share
									</h6>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Banner;
