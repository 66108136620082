import React, { useRef } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import img from "../../assets/img/creator1.png";
import ActivityCard from "../../components/activity-card/ActivityCard";
import CountdownItem from "../../components/CountdownItem";
import {
	NextSlide,
	PrevSlide,
} from "../../components/creators/components/SliderNav";
import { ArrowIcon, Clock, Heart } from "../../icon/IconTheme";
import RadialChart from "./components/RadialChart";

const Dashboard = () => {
	const sliderRef = useRef();
	const nextSlide = () => {
		sliderRef.current.slickNext();
	};
	const prevSlide = () => {
		sliderRef.current.slickPrev();
	};
	const settings = {
		dots: false,
		infinite: true,
		speed: 300,
		slidesToShow: 2,
		slidesToScroll: 1,
		arrows: false,
		margin: 30,
		centerMode: false,
		stagePadding: 0,
		responsive: [
			{
				breakpoint: 1199,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
				},
			},
		],
	};
	return (
		<section className="pt-40 pb-80">
			<div className="container">
				<h1 className="layout-title">Dashboard</h1>
				<main className="row gy-5">
					<article className="col-lg-8 col-xl-9 __dashboard-article">
						<div className="mb-40">
							<div className="row g-4 g-md-3 g-xl-4">
								<div className="col-md-4 col-sm-6">
									<div className="dashboard-card">
										<div className="arrow">
											<ArrowIcon />
										</div>
										<span className="subtxt">Overview</span>
										<h5 className="title">$186.4k</h5>
										<div className="d-flex justify-content-between">
											<span>Total Earnings</span>
											<span>+10%</span>
										</div>
									</div>
								</div>
								<div className="col-md-4 col-sm-6">
									<div className="dashboard-card">
										<div className="arrow">
											<ArrowIcon />
										</div>
										<span className="subtxt">Performance</span>
										<h5 className="title">4.950</h5>
										<div className="d-flex justify-content-between">
											<span>Properties Invested</span>
											<span>+8</span>
										</div>
									</div>
								</div>
								<div className="col-md-4 col-sm-6">
									<div className="dashboard-card">
										<div className="arrow">
											<ArrowIcon />
										</div>
										<span className="subtxt">Conversion</span>
										<h5 className="title">2.148</h5>
										<div className="d-flex justify-content-between">
											<span>Total Earni NGS</span>
											<span>+2%</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="section-title">
							<h4 className="title">My Properties</h4>

							<div className="nav-btns">
								<div onClick={prevSlide}>
									<PrevSlide />
								</div>
								<div onClick={nextSlide}>
									<NextSlide />
								</div>
							</div>
						</div>
						{/* Slider */}
						<div className="right-overflow mb-40">
							<div className="creator-slider">
								<Slider {...settings} ref={sliderRef}>
									{["", "", "", "", ""].map((item, i) => (
										<MyCreateCard key={i} />
									))}
								</Slider>
							</div>
						</div>
						{/* Slider */}
						<ActivityCard data={["", "", ""]} />
					</article>
					<aside className="col-lg-4 col-xl-3">
						<RadialChart />
					</aside>
				</main>
			</div>
		</section>
	);
};

export const MyCreateCard = () => {
	return (
		<div className="creator-card">
			<div className="creator-img">
				<Link to="#">
					<img src={img} alt="" />
				</Link>
				<div className="left-badge chip">
					<Clock />
					<CountdownItem targetDate={`December 31, 2022 00:00:00`} />
				</div>
				<div className="right-badge chip">
					<Heart /> <span>12</span>
				</div>
			</div>
			<div className="creator-cont">
				<Link to="#">
					<h5 className="name">Patturb and Waymo</h5>
				</Link>
				<ul className="info">
					<li>
						<span>Total Piece</span>
						<h6>Single Family Home</h6>
					</li>
					<li>
						<span>My Piece</span>
						<h6>100</h6>
					</li>
					<li>
						<span>Returns Per Piece</span>
						<h6>$4000</h6>
					</li>
					<li>
						<span>Total Investments </span>
						<h6>
							<small>Monthly Per Price</small> / $30
						</h6>
					</li>
					<li>
						<button className="cmn-btn w-100">Interact</button>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default Dashboard;
