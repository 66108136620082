export const recommendedData = ([
  {type : "Condo",
  img : "https://i.ibb.co/NWGB1vN/473f5459-c892-4b4a-9b1f-e96e9eff553d.jpg",
  img2 : "https://ap.rdcpix.com/044707f6cd7cbfac44e2500bb824527bl-m4161533880od-w1024_h768_x2.webp",
  img3 : "https://ap.rdcpix.com/044707f6cd7cbfac44e2500bb824527bl-m865646911od-w1024_h768_x2.webp",
  img4 : "https://ap.rdcpix.com/044707f6cd7cbfac44e2500bb824527bl-m1868320962od-w1024_h768_x2.webp,",
  pieces : 1000,
  pricePerPiece : 689,
  APR : 45,
  address : "137 2nd St Lewes Historic District",
  ListingPrice : 689000,
  ca : "0x345e862e82f15582582184138a48eebda711aa3f",
  },
  {type : "Condo",
  pieces : 100,
  img : "https://i.ibb.co/xzx6PV7/4994eb79-589e-4468-ab98-f451457f61c4.jpg",
  pricePerPiece : 5500,
  APR : 45,
  ca : "0x345e862e82f15582582184138a48eebda711aa3f",
  address : "Bolzano, Trentino-Alto Adige/South Tyrol, Italy",
  ListingPrice : 1290000
   },
  {type : "Condo",
  pieces : 100,
  img : "https://ap.rdcpix.com/a46350caefe0a518e32ebf5c2f385963l-m2298921815od-w1024_h768_x2.webp",
  pricePerPiece : 1090,
  APR : 45,
  ca : "0x345e862e82f15582582184138a48eebda711aa3f",
  address : "10 Harbor Blvd Unit E105H, Destin, FL 32541 ",
  ListingPrice : 109000
  },
]);



export const listed_property = [
{
  images: ["https://ap.rdcpix.com/28133a4827c8a38987db07703f0068f6l-b3026663867od-w480_h360_x2.webp", "https://ap.rdcpix.com/28133a4827c8a38987db07703f0068f6l-b3026663867od-w480_h360_x2.webp", "https://ap.rdcpix.com/28133a4827c8a38987db07703f0068f6l-b3026663867od-w480_h360_x2.webp"],
  id: "Ont, CA 02",
  fee: "5% Broker Fee",
  qty: "100",
  contract: "0x123456789011",
  APR: "23",
  status: "listed",
  ca : "0x81636cb63146A162253Bdd9A87121813469ec163",
},

];