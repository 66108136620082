import React from "react";
import { Link, useParams } from "react-router-dom";
import { Share } from "../../../icon/IconTheme";
import img from "./banner-bg.png";
import banner from "./store-banner.png";
import { brokerabi , usdtABI} from "../../../constants/abi/broker";
import { getPropertyName, getPropertyID, getPropertyAddress, getOwner, checkApproval, getPropertyPrice, checkAllowance} from "../../../constants/functions/properties";
import truncateEthAddress from 'truncate-eth-address'
import { getPieceDetails } from "../../../constants/functions/broker";
import { propertyabi } from "../../../constants/abi/properties";
import { useAccount } from "wagmi";
import { useWeb3Modal } from "@web3modal/react";
import { useProvider } from 'wagmi'
import { useSigner } from 'wagmi'
import { ethers } from "ethers";
import * as Alerts from "../../../../src/alerts/Alert.js";



const StoreBanner = () => {
	const {open} = useWeb3Modal();
	const { data: signer, isError, isLoading } = useSigner({
		chainId : 404,
	})
	const {address ,isConnected, isDisconnected } = useAccount()
	const {piece, property} = useParams();
	const [price, setPrice] = React.useState("6");
	const [isListed , setIsListed] = React.useState(false);
	const [lister , setLister] = React.useState("undefined");
	const [name, setName] = React.useState("Xend Capitals");
	const [id, setId] = React.useState("xxR");
	const [APR , setAPR] = React.useState(5);
	const [owner, setOwner] = React.useState("undefined");
	const [propertyAddress, setpropertyAddress] = React.useState("");
	const [approved, setApproved] = React.useState(false);


	const Approve = async () => {
		const usdt = new ethers.Contract("0x55d398326f99059fF775485246999027B3197955", usdtABI, signer)
    	const tx = await usdt.approve(property, "1000000000000000000000000")
		const reciept = await tx.wait()
		console.log(reciept)
		if (reciept && reciept.blockNumber) {
			console.log(reciept)
			setApproved(true);
		}
		
	}

	 

	
	const buyPiece = async () => {
		const Propertycontract = new ethers.Contract(property, propertyabi, signer)
    	const tx = await Propertycontract.purchase("1");
		const reciept = await tx.wait()
		console.log(reciept)
		if (reciept && reciept.blockNumber) {
			console.log(reciept)
			setApproved(true);
		}
		
	}


	const greenButton = () => {
		if(!isConnected) {
			return {
				title : "Connect Wallet",
				func : open
			}
		} else if (!approved){
				return {
					title : "Enable Trading",
					func : Approve
				}
			} else {
			return {
				title : "Buy",
				func : buyPiece
			}
		}
	}
	const redButton = () => {
		if(!isConnected) {
			return {
				title : "Connect Wallet",
				func : open
			}} else if (owner === address && !approved){
				return {
					title : "Enable Trading",
					func : open
				}}  else {
				return {
					title : "Explore",
					func : () => {}
				}
			}
	}
	const red = redButton();
	const green = greenButton();



	const init = async () => {
		const allo = (await checkAllowance("0x55d398326f99059fF775485246999027B3197955", address, property))
		setApproved((allo));
		const name = await getPropertyName(property);
		setName(name);
		const id = await getPropertyID(property);
		setId(id);
		console.log(await getPropertyPrice())
		setPrice(String(await getPropertyPrice(property)));


		if (id === "1150SR") {
			setpropertyAddress("1150 Santa Rosa Blvd Unit 522, Fort Walton Beach, FL 32548")
		}

		


	}

	React.useEffect(()=> {
		 init();
	}, [])
	return (
		<>
			<section
				className="store-banner-section"
				style={{
					background: `url(${img}) no-repeat bottom center / cover`,
				}}
			>
				<div className="container">
					<div className="row g-4">
						<div className="col-lg-6">
							<img src={"https://ap.rdcpix.com/28133a4827c8a38987db07703f0068f6l-b3026663867od-w480_h360_x2.webp"} alt="" className="store-img" />
						</div>
						<div className="col-lg-6">
							<div className="store-cont-top">
								<span className="cmn-btn secondary-btn me-auto">
									Status : Available
								</span>
								<span className="cmn-btn btn-outline">
									Shares Supply: 100
								</span>
								<Link to="#" className="cmn-btn btn-outline icon-btn">
									<Share />
								</Link>
							</div>
							<div className="store-content">
								<h1 className="title">{name} - {id}</h1>
								<p>
								{propertyAddress}
								</p>
							</div>
							<div className="card __card">
								<div className="card-header">
									<div className="w-100">
										<div className="row g-4">
											<div className="col-sm-6">
												<div className="__p-card">
													<div className="subtext">
														Piece Price
													</div>
													<h5 className="value"> {price > 0 ?  "$ " + price : "Unlisted"}</h5>
												</div>
											</div>
											<div className="col-sm-6">
												<div className="__p-card">
													<div className="subtext">
														Estimated APR
													</div>
													<h5 className="value">
													{APR}%  <span>Per Piece</span>
													</h5>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="card-body">
									<div
										className="d-flex __btns-grid"
										style={{ gap: "22px" }}
									>
										<a onClick={green.func} className="cmn-btn">
											{green.title}
										</a>
										<a onClick={red.func} className="cmn-btn btn-danger">
											{red.title}
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default StoreBanner;
