import React, { useRef } from "react";
import Slider from "react-slick";
import img from "../../assets/img/client1.png";
import { NextSlide, PrevSlide } from "../creators/components/SliderNav";

let reviews = [
		{
			name: "Tammy ***",
			profession: "Xend Customer",
			content: "I'm a teacher but has always wanted to own real estate investment without getting a loan or breaking the bank, It was hard at first but Xend capital limited made it possible. The team at Xend capital has been some of the best brilliant people I have come across. They are bringing innovation on the traditional way the real estate industry has worked!"
		},
		{
			name: "Christopher ***",
			profession: "Xend Customer",
			content: "The process of starting investment and owning property has never been this easier. With little to no waste of time, I can invest and monitor all my properties from anywhere in the world. Thank you Xendcapital."
		},
		{
			name: "Crystal ***",
			profession: "Xend Customer",
			content: "Xendcapital has the best approach in  integration of blockchain technology in real estate, I highly recommend this to anyone looking for the best prices in retail real estate."
		},
		{
			name: "Nicholas ***",
			profession: "Xend Customer",
			content: "This is by far the most retail approach in real estate investment I can confidently recommend. Using blockchain technology in real estate has been one of the biggest recent  discussion in innovative real estate and Xendcapital capital just made it possible."
		},
	];


const Customers = () => {
	console.log(reviews);
	const sliderRef = useRef();
	const nextSlide = () => {
		sliderRef.current.slickNext();
	};
	const prevSlide = () => {
		sliderRef.current.slickPrev();
	};
	const settings = {
		dots: true,
		infinite: true,
		speed: 300,
		slidesToShow: 3,
		slidesToScroll: 1,
		arrows: false,
		margin: 30,
		responsive: [
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
				},
			},
		],
	};
	return (
		<section className="customer-section">
			<div className="container">
				<div className="section-title justify-content-center">
					<h4 className="title">Xend Investor reviews</h4>
				</div>
				<div className="container">
					{/* Client Slider */}
					<div className="client-slider">
						<Slider {...settings} ref={sliderRef}>
							{reviews.map((item, i) => (
								<CustomerCard reviews={reviews[i]} key={i}/>
							))}
						</Slider>
					</div>
				</div>
				<div className="container">
					<div className="navigation-btns d-flex justify-content-between">
						<div onClick={prevSlide}>
							<PrevSlide />
						</div>
						<div onClick={nextSlide}>
							<NextSlide />
						</div>
					</div>
				</div>
				{/* Client Slider */}
			</div>
		</section>
	);
};

export const CustomerCard = (props) => {
	let reviewer = props.reviews;
	return (
		<>
			<div className="client-card bg-section">
				<div className="client-card-top">
					{/* <img src={img} alt="" /> */}
					<div className="cont">
						<h6 className="name">{reviewer.name}</h6>
						<span>{reviewer.profession}</span>
					</div>
				</div>
				<div className="content">
					{reviewer.content}
				</div>
			</div>
		</>
	);
};
export default Customers;
