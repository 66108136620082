import React from "react";
import { Link } from "react-router-dom";
import { social_icons } from "../../assets/data/social";
const SocialIcons = () => {
	return (
		<>
			<ul className="social-icons">
				{social_icons &&
					social_icons.map(({ icon, link }, i) => (
						<li key={i}>
							<Link to={link}>
								{icon}
							</Link>
						</li>
					))}
			</ul>
		</>
	);
};

export default SocialIcons;
