import React from "react";
import { Link } from "react-router-dom";
import { about_list } from "../../assets/data/about";
import img from "../../assets/img/about.svg";
import {
	default as img1,
	default as img2,
	default as img3,
} from "../../assets/img/about/review-1.png";
import { Check, Ellipsis } from "../../icon/IconTheme";
const About = () => {
	return (
		<section className="about-section overflow-hidden">
			<div className="container">
				<div className="row gy-5 justify-content-center justify-content-lg-between">
					<div className="col-lg-5 col-md-10">
						<div className="about-img">
							<img src={img} className="mw-100" alt="" />
							<img src={img1} alt="" className="img1" />
							<img src={img2} alt="" className="img2" />
							<img src={img3} alt="" className="img3" />
						</div>
					</div>
					<div className="col-lg-6">
						<div className="about-content">
							<h2 className="title">
								Who we are!
							</h2>
							<p className="text">
							Xend capital is an innovative real estate firm registered in the  United Kingdom. <br></br>
 xend capital makes investment in real estate anywhere in the world easy by combining industry best practice and blockchain technology through using NFT as a proof of ownership in real estate.<br></br><br></br>
Xend makes owning rental property easy and seamless across the world by getting the best property in the location and making it possible
 For small and medium investors to own property in the best location.
							</p>
							<ul>
								{about_list &&
									about_list.map((item, i) => (
										<li key={i}>
											<Check />
											<span>{item}</span>
										</li>
									))}
							</ul>
							<div className="btn-grp">
								<a href="https://find-and-update.company-information.service.gov.uk/company/14567175" className="cmn-btn">
									Company Details 
								</a>
								{/* <Link to="#" className="cmn-btn secondary-btn">
									Company <Ellipsis />
								</Link>
							 */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default About;
