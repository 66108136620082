import React, { useState } from "react";
import { Link } from "react-router-dom";
import { listed_property } from "../../data/recommended";
import { AngleDown, Calendar, Dots, Ellipsis } from "../../icon/IconTheme";

const ListedProperties = () => {
	return (
		<>
			<section className="listed-property">
				<div className="container">
					<div className="d-flex flex-wrap justify-content-between align-items-center __listed-props-title">
						<h1 className="m-0">LISTED PROPERTIES</h1>
						<Link to="#" className="cmn-btn btn-outline">
							<Calendar /> Last 30 days
						</Link>
					</div>
					<div className="table-responsive">
						<table className="table __table">
							<thead>
								<tr>
									<th>
										<div>
											
										</div>
									</th>
									<th>
										<div>
											id <Dots />
										</div>
									</th>
									<th>
										<div>
											Fee <Dots />
										</div>
									</th>
									<th>
										<div>
											Qty <Dots />
										</div>
									</th>
									<th>
										<div>
										APR <Dots />
										</div>
									</th>
									<th>
										<div>
											Status <Dots />
										</div>
									</th>
								</tr>
							</thead>
							{listed_property ? (
								<tbody>
									{listed_property.map((item, i) => (
							
									<TableRow key={i} {...item} /> 
								
									))}
								</tbody>
							) : (
								<tr>
									<td
										className="text-center text-white pt-4"
										colSpan={12}
									>
										No Data Yet
									</td>
								</tr>
							)}
						</table>
					</div>
				</div>
			</section>
		</>
	);
};
export const TableRow = ({ images, id, fee, qty, APR, status, ca}) => {
	console.log(APR)
	const [dropdownOpen, setDropdownOpen] = useState(false);
	return (
		<>
	
			<tr>
				<td className="border-0" style={{ padding: "5px" }}></td>
			</tr>
			<tr>
				<td style={{ width: "360px" }}>
					<div className="__img-container">
						{images &&
							images.map((img, i) => <img  src={img} key={i} alt="" />)}
					</div>
				</td>
				

				<td>
					<div className="word-nobreak">{id}</div>
				</td>
				<td>
					<div className="word-nobreak">{fee}</div>
				</td>
				<td>
					<div className="ps-3">{qty}</div>
				</td>
				<td>
					<div className="word-nobreak">{APR}%</div>
				</td>
				<td style={{ width: "220px" }}>
					<div className="d-flex align-items-center">
						<span className="cmn-btn btn-outline __status">
							{status === "pending" ? (
								<>
									<span className="dots">
										<Dots fill="#F4C952" />
									</span>
									Pending
								</>
							) : status === "listed" ? (
								<>
									<span className="dots">
										<Dots fill="var(--base)" />
									</span>
									Listed
								</>
							) : (
								""
							)}
						</span>
						<div className="ms-3 ms-xl-4 position-relative">
							<Link to="#">
								<Ellipsis />
							</Link>
							<ul
								className={`__dropdown-menu dropdown-menu ${
									dropdownOpen ? "active" : ""
								}`}
								onClick={() => setDropdownOpen(false)}
								onMouseDown={() => setDropdownOpen(true)}
							>
								<li>
									<a className="dropdown-item" href={`${ca}`}>
										View
									</a>
								</li>
								
							</ul>
						</div>
					</div>
				</td>
			</tr>
			
		</>
	);
};


export default ListedProperties;
