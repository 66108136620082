import React from "react";
import Marquee from "react-fast-marquee";
import { partners } from "../../assets/data/partners";
const Partner = () => {
	return (
		<section className="partner-section bg-section">
			<Marquee speed={30} pauseOnHover={true} gradient={false}>
				{partners &&
					partners.map(({ img }, i) => (
						<img
							src={img}
							alt="partner"
							className="partner-img"
							key={i}
						/>
					))}
			</Marquee>
		</section>
	);
};

export default Partner;
