import React, { useState } from "react";
import { ETHIcon } from "../../icon/IconTheme";

const ActivityCard = ({ data, filter }) => {
	const [active, setActive] = useState(1);
	return (
		<div className="card __card">
			<div className="card-header">
				<h6 className="card-title">Activity</h6>
			</div>
			<div className="card-body">
				{filter && (
					<ul className="__filter">
						{filter.map((item, i) => (
							<li
								key={i}
								className={active === i ? "active" : ""}
								onClick={() => setActive(i)}
							>
								{item}
							</li>
						))}
					</ul>
				)}
				<ul className="activity-list">
					{data &&
						[''].map((item, i) => (
							<li key={i}>
								<h6 className="title">
									 <span>Listed (43) for sale</span>
								</h6>
								<div className="text-end">
									<h6>
										<ETHIcon /> 475.98 BNB
									</h6>
									<div>≈ $140.05</div>
								</div>
							</li>
						))}
				</ul>
			</div>
		</div>
	);
};

export default ActivityCard;
