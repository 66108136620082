import React from "react";
import { AngleLeft, AngleRight } from "../../../icon/IconTheme";

export const NextSlide = () => {
	return (
		<div className="slide-next">
			<AngleRight />
		</div>
	);
};

export const PrevSlide = () => {
	return (
		<div className="slide-prev">
			<AngleLeft />
		</div>
	);
};
