import cogoToast from '@successtar/cogo-toast';
import "./Alert.css"

export const AssetListed = () => {
    cogoToast.success('Asset Listed for sale!', {position : 'top-center', });
  }
  export const AssetUnlisted = () => {
    cogoToast.error('Asset Unlisted', {position : 'top-center', });
}
export const AlertConnectWallet = () => {
    cogoToast.success('Wallet Connected', {position : 'top-center', });
}
export const AlertDisConnectWallet = () => {
    cogoToast.info('Wallet Disconnected', {position : 'top-center', });
}
export const AlertInsufficentBalance = () => {
    cogoToast.error('Insufficient Balance for Purchase!', {position : 'top-center', });
}

export const AlertRejectedTXN = () => {
    cogoToast.error("User denied transaction signature", {position : 'top-center', });
}
export const AlertMaxBuy = () => {
    cogoToast.error('Max allocation reached', {position : 'top-center', });
}
export const AlertTxnSubmitted = () => {
    cogoToast.loading('Transaction Pending', {position : 'top-center', hideAfter : 4});
}
export const AlertTxnConfirmed = () => {
    cogoToast.success('Transaction Successful', {position : 'top-center', });
}
export const AlertWalletSwitched = () => {
    cogoToast.info('Account Switched', {position : 'top-center', });
}
export const AlertFailedTXN = () => {
    cogoToast.error("Transaction Failed", {position : 'top-center', });
}

export const AlertMintFailed = (e) => {
    cogoToast.error(e, {position : 'top-center', });
}