import React from "react";
import Creators from "../../components/creators/Creators";
import StoreBanner from "./components/StoreBanner";
import StoreProperty from "./components/StoreProperty";

const Store = () => {
	
	return (
		<>
			<StoreBanner />
			<StoreProperty />
			<Creators title="Recommended Properties" />
		</>
	);
};

export default Store;
