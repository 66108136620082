import React from "react";
const data = [
	{
		title: "Connect wallet",
		text: "Xendcapital is a dapp like Pancakeswap, you need to connect your wallet to start.",
	},
	{
		title: "Find a property",
		text: "Find a property you would like to own a share of",
	},
	{
		title: "Purchase",
		text: "Purchase Property NFTs with USDT",
	},
	{
		title: "Relinquish",
		text: "Give up your share anytime to get initial investment",
	},
	
];
const CreateNft = () => {
	return (
		<section className="nft-section">
			<div className="container">
				<h2 className="nft-title text-center">Purchase steps</h2>
				<div className="nft-container">
					{data &&
						data.map(({ title, text }, i) => (
							<div className="nft-card" key={i}>
								<div className="text">
									<h4 className="title">{title}</h4>
									<div>{text}</div>
								</div>
							</div>
						))}
				</div>
			</div>
		</section>
	);
};

export default CreateNft;
