import { ethers } from "ethers";
import { propertyabi } from "../abi/properties";
import { usdtABI } from "../abi/broker";


let broker = "0x03b7354cF50efeAC2882094B94846c9EE935cb4C"
export const getPropertyName = async (contract) => {
    try{
    let provider = new ethers.providers.Web3Provider(window.ethereum);
    let propertyContract = new ethers.Contract(contract, propertyabi, provider);
    let name = await propertyContract.name();
    return name;
    } catch (e){
        console.log(e)
    }
}

export const getPropertyID = async (contract) => {
    let provider = new ethers.providers.Web3Provider(window.ethereum);
    let propertyContract = new ethers.Contract(contract, propertyabi, provider);
    let id = await propertyContract.symbol();
    return id;
}
export const getPropertyAddress = async (contract) => {
    return "i love"
}
export const getPropertyPrice = async (contract) => {
    console.log("get price")
    try{
    let provider = new ethers.providers.Web3Provider(window.ethereum);
    let propertyContract = new ethers.Contract(contract, propertyabi, provider);
    let price = await propertyContract.floorPrice();
    console.log(String(price), "price") ;
    return ethers.utils.formatEther(price);
    return price / 10 ** 18;
}   catch (e){
    console.log(e);
}
}

export 	const checkAllowance = async (address, owner, contract_to_check) => {
    let provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(address, usdtABI, provider);
    const allowance = await contract.allowance(owner, contract_to_check);
    return allowance;
}
export const checkApproval = async (contract, addr) => {
    try {
    let provider = new ethers.providers.Web3Provider(window.ethereum);
    let propertyContract = new ethers.Contract(contract, propertyabi, provider);
    let approval = await propertyContract.isApprovedForAll(addr, "0x03b7354cF50efeAC2882094B94846c9EE935cb4C");
    return approval;
    } catch (e){
        console.log(e)
    }
}


// write Functions

