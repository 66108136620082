import React from "react";
import { Link } from "react-router-dom";
import { BrightnessIcon, Envelop, GlobeIcon } from "../../icon/IconTheme";
import SocialIcons from "../social-icons/SocialIcons";

const Footer = () => {
	return (
		<footer className="bg-section">
			<FooterTop />
			<FooterBottom />
		</footer>
	);
};
const data = [
	{
		link: "#",
		name: "Explore",
	},
	{
		link: "#",
		name: "Help Center",
	},
	{
		link: "#",
		name: "Become a Partner",
	},
	{
		link: "#",
		name: "About Us",
	},
	{
		link: "#",
		name: "Platform Status",
	},
];
const data2 = [
	{
		link: "#",
		name: "Profile",
	},
	{
		link: "#",
		name: "Favorites",
	},
	{
		link: "#",
		name: "Watchlist",
	},
	{
		link: "#",
		name: "My Collections",
	},
	{
		link: "#",
		name: "Rankings",
	},
	{
		link: "#",
		name: "Activity",
	},
];
export const FooterTop = () => {
	return (
		<>
			<div className="footer-top">
				<div className="container">
					<div className="footer-wrapper">
						<div className="footer-widget widget-about">
							<h6 className="title">Subscribe to updates</h6>
							<form>
								<div className="__subscribe-form">
									<input
										type="text"
										className="form-control"
										placeholder="Enter your e-mail"
									/>
									<div className="icon">
										<Envelop />
									</div>
								</div>
							</form>
							<h6 className="title mt-57">Follow us</h6>
							<SocialIcons />
						</div>
						<div className="footer-widget widget-link">
							<h6 className="title">Marketplace</h6>
							<LinkList data={data} />
						</div>
						<div className="footer-widget widget-link">
							<h6 className="title">Community</h6>
							<LinkList data={data2} />
						</div>
						<div className="footer-widget widget-region">
							<h6 className="title">Region</h6>
							<div className="text">
								The world's first marketplace for collectibles and
								non-fungible tokens NFTs where anything is possible and
								all are welcome
							</div>
							<select className="cmn-btn outline-btn currency-select">
								<option>Currency - USD</option>
								<option>Currency - BDT</option>
								<option>Currency - INR</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export const LinkList = ({ data }) => {
	return (
		<>
			<ul>
				{data &&
					data.map(({ link, name }, i) => (
						<li key={i}>
							<Link to={link}>{name}</Link>
						</li>
					))}
			</ul>
		</>
	);
};
export const FooterBottom = () => {
	return (
		<>
			<div className="footer-bottom">
				<div className="container">
					<div className="footer-bottom-wrapper">
						<ul className="links">
							<li>
								<Link to="#">Privacy Policy</Link>
							</li>
							<li>
								<Link to="#">License</Link>
							</li>
							<li>
								<Link to="#">API</Link>
							</li>
						</ul>
						<div className="copyright">
							&copy; 2021 All rights reserved
						</div>
						<div className="lang-area">
							<div className="__lang-control">
								<select className="form-control">
									<option>English</option>
									<option>Bangla</option>
									<option>Hindi</option>
									<option>Urdu</option>
									<option>Spanish</option>
								</select>
								<div className="globe-icon">
									<GlobeIcon />
								</div>
							</div>
							<Link to="#" className="color-theme-icon">
								<BrightnessIcon />
							</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default Footer;
